<template>
  <b-row>
    <b-col cols="3">
      <div class="mb-4 d-flex" style="margin-bottom: 0px !important;">
        <b-button style='border-radius: 0px; flex: 1' variant="secondary" @click="$router.push('/place')"><font color="white">신청 관리</font></b-button>
        <b-button style='border-radius: 0px; flex: 1' variant="primary" @click="$router.push('/placeBill')"><font color="white">이용 관리</font></b-button>
      </div>
      <br/>
      <TreeView :setDong="setDong" :selected="dongSearch == '' ? '전체' : dongSearch"></TreeView>
    </b-col>
    <b-col cols="9">
      <b-card no-body class="mb-4">
        <b-card-body class="p-3">
          <h5 class="card-title mb-0">시설 이용 내역</h5>
        </b-card-body>

        <b-table
          responsive
          class="mb-0 p-3"
          head-variant="light"
          :items="items"
          :current-page="currentPage"
          :per-page="perPage"
          :fields="fields"
        >
          <template #cell(changeMonth)="data">
            <font color="red" v-if="data.item.changeMonthBold">{{data.item.changeMonth}}</font>
            <font color="black" v-if="!data.item.changeMonthBold">{{data.item.changeMonth}}</font>
          </template>

          <template #cell(status)="data">
            <b-button variant="warning" v-if="(data.item.status == 1)"><font color="white"><i class="font-9 mdi mdi-eye"></i> 확인</font></b-button>

            <b-button variant="danger" @click="deny(data.item.idx)" v-if="(data.item.status == 0)"><font color="white"><i class="font-9 mdi mdi-account-remove"></i> 거절</font></b-button>&nbsp;
            <b-button variant="success" v-if="(data.item.status == 0)"><font color="white"><i class="font-9 mdi mdi-account-check"></i> 승인</font></b-button>
          </template>
        </b-table>

        <div class="row p-3 m-0">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            class="my-0"
          ></b-pagination>
        </div>

      </b-card>
    </b-col>
  </b-row>
</template>

<style>
  .b-table {
    border: 1px solid #eee
  }
</style>

<script>
import TreeView from '../components/apps/TreeView';
export default {
  name: "PlaceBill",

  data: () => ({
    page: {
      title: "PaginationTable",
    },
    fields: [
      {
        key: "target",
        label: "신청 세대",
      },
      {
        key: "place",
        label: "시설",
      },
      {
        key: "changeMonth",
        label: "변경 적용 월",
      },
      {
        key: "money",
        label: "월 사용료",
      }
    ],
    items: [
      {idx: "1", target: "102동 1301호", place: "헬스장", changeMonth: "22년 12월", money: "18만원"},
      {idx: "2", target: "507동 602호", place: "골프 연습장, 헬스장", changeMonth: "22년 11월", money: "28만원"},
      {idx: "3", target: "108동 304호", place: "수영장", changeMonth: "22년 05월", money: "3만원"},
      {idx: "4", target: "408동 902호", place: "골프 연습장", changeMonth: "22년 04월", money: "10만원"},
      {idx: "5", target: "511동 1504호", place: "골프 연습장", changeMonth: "22년 03월", money: "10만원"},
      {idx: "6", target: "208동 1302호", place: "수영장", changeMonth: "22년 01월", money: "3만원"},
      {idx: "7", target: "106동 103호", place: "헬스장", changeMonth: "21년 06월", money: "8만원"},
      {idx: "8", target: "504동 1101호", place: "수영장", changeMonth: "21년 05월", money: "3만원"},
    ],
    totalRows: 0,
    currentPage: 1,
    perPage: 10,
    dongSearch: "",

  }),
  components: {TreeView},
  methods: {
    setDong(dong, isChild){
      if(isChild){
        this.dongSearch = dong;
      }
      else{
        this.dongSearch = "";
      }
    }
  },
  mounted() {
    this.totalRows = this.items.length;
  },
};
</script>
